import Register from '../../../utils/Register';

export default class ProductAddToCart {

	constructor ($container) {
		this.container = $container;

		const _this = this;
		const $select = $container.find('select[name="productVariationId"]');

		const $quantity = $container.find('[name="quantity"]');
		const $submit = $container.find('[name="send"]');

		$quantity.prop('disabled', true);
		$submit.prop('disabled', true);

		if ($select.val() !== '') {
			let variationData = $select.find(':selected').data('product-variation');
			this.displayBuy(variationData.inCart);
		}

		this.changeVariation($select);
		$select.on('change', function(e) {
			_this.changeVariation($(this));
		});
	}

	changeVariation($select) {
		const val = $select.val();
		const variationData = $select.find(':selected').data('product-variation');
		const $availabilityContainer = $('.js-product-variation-availability');

		if (val !== '') {
			this.displayBuy(variationData.inCart);

			$availabilityContainer.prop('hidden', false);
			const newClass = $availabilityContainer.find('[data-availabiltity-name]').data('base-class') + ' ' + $availabilityContainer.find('[data-availabiltity-name]').data('append-class') + variationData.availability.id;
			$availabilityContainer.find('[data-availabiltity-name]').removeAttr('class').html(variationData.availability.name).attr('class', newClass);
			$availabilityContainer.find('[data-availabiltity-description]').html(variationData.availability.description);

			this.enableBuy(variationData.inCart, variationData.quantity);
		} else {
			this.displayBuy();

			$availabilityContainer.prop('hidden', true);
			this.disableBuy();
		}
	}

	enableBuy(inCart, quantity) {
		const $quantity = this.container.find('[name="quantity"]');
		const $submit = this.container.find('[name="send"]');

		$quantity.val(1).prop('disabled', false);
		$quantity.removeAttr('data-max');
		$quantity.removeData('max');

		if (quantity !== null) {
			$quantity.data('max', quantity);
		}

		$submit.prop('disabled', false);

		$quantity.closest('.js-spinbox').data('spinbox').updateButtons();
	}

	disableBuy() {
		const $quantity = this.container.find('[name="quantity"]');
		const $submit = this.container.find('[name="send"]');

		$quantity.data('max', 1);

		$quantity.val(1).prop('disabled', true);
		$submit.prop('disabled', true);

		$quantity.closest('.js-spinbox').data('spinbox').updateButtons();
	}

	displayBuy(inCart) {
		const $inCartContainer = this.container.find('[data-in-cart]');
		const $addToCartContainer = this.container.find('[data-add-to-cart]');

		if (inCart === undefined || inCart === false) {
			$inCartContainer.prop('hidden', true);
			$addToCartContainer.prop('hidden', false);
		} else {
			$inCartContainer.prop('hidden', false);
			$addToCartContainer.prop('hidden', true);
		}
	}

	static init ($container) {
		const $form = $container.find('.js-product-add-to-cart');
		if ($form.length > 0) {
			new ProductAddToCart($form);
		}
	}

}

(new Register()).registerCallback(ProductAddToCart.init, 'ProductAddToCart.init');